/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 09.06.2021.
 */

"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import delegate from 'es6-delegate/delegate';

class Featurehighlights extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._items = this._element.querySelectorAll('.featurehighlights__item');
		this._activeIndex = 0;
		this._init();
	}

	_init() {
		this._element.dataset.init = true;
		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		if (window.device === 'desktop') {
			delegate(this._element, 'mouseenter', '.featurehighlights__item', this._onMouseOver.bind(this));
			delegate(this._element, 'mouseleave', '.featurehighlights__item', this._onMouseOut.bind(this));
		} else {
			delegate(this._element, 'click', '.featurehighlights__item', this._onClick.bind(this));
		}
	}

	_onClick(evt, target) {
		// Prevent default if the item is NOT already active
		if (!target.classList.contains('featurehighlights__item--active')) {
			evt.preventDefault();
			console.log("Default action prevented.");
		}
	
		const index = parseInt(target.dataset.index);
		console.log(`Clicked item index: ${index}`);
	
		// Switch image to match the clicked item
		this._switchImage(index);
	
		// Remove active class from all items and set only the clicked one as active
		this._items.forEach(item => item.classList.remove('featurehighlights__item--active'));
		target.classList.add('featurehighlights__item--active');
	}
	

	_switchImage(index) {
		const activeImage = this._element.querySelector(`.featurehighlights__image[data-index="${this._activeIndex}"]`);
		const image = this._element.querySelector(`.featurehighlights__image[data-index="${index}"]`);

		console.log(`Switching image: Active index = ${this._activeIndex}, New index = ${index}`);

		let realImage = null;
		if (image) {
			realImage = image.querySelector('.image');
		}

		if (image && activeImage && realImage) {
			activeImage.classList.remove('featurehighlights__image--active');
			image.classList.add('featurehighlights__image--active');
			this._activeIndex = index;
		}
	}

	_onMouseOver(evt, target) {
		const index = parseInt(target.dataset.index);
		console.log(`Mouse over item: ${index}`);
		this._switchImage(index);
	}

	_onMouseOut(evt, target) {
		console.log("Mouse out, resetting image to default.");
		this._switchImage(0);
	}
}

export default Featurehighlights;
