/**
 * project: Pimcore - Fieldcode
 * Created by EBiermann on 23.03.2020.
 */
"use strict";

import BaseClass from "../classes/BaseClass";
import Init from "../classes/Init";
import Events from "../fieldcode.events";
import * as Utils from "../utilities/Utilities";
import DKPagination from "../classes/Pagination";

import Swiper from 'swiper';
import { Pagination, EffectFade, Autoplay } from 'swiper/modules';

class Section extends BaseClass.inherit(Init) {
	constructor(element, referenceElement, app) {
		super(element, referenceElement, app);

		this._gridItems = this._element.querySelectorAll('.grid');
		this._areaItems = this._element.querySelectorAll('.area');
		this._asSlider = false;
		this._asSliderWrapper = this._element.querySelector('.section__slider');
		this._asTabs = false;
		this._headerElement = this._element.querySelector('.section-header');
		this._paginationElement = this._element.querySelector('.section-header__pagination');
		this._pagination = null;
		this._autoplay = 0;
		this._adaptiveHeight = false;

		this._swiper = null;
		this._swiperOptions = {}
		this._init();
	}

	//public

	//private

	_init() {
		let initialIndex = 0, pageDots = false;

		this._element.dataset.init = true;
		this._app.handlers.intersection.attach(this._element);
		this._asSlider = this._element.classList.contains('section--slider') ?? false;
		if (!this._asSlider) {
			this._asSlider = this._element.classList.contains('section--tabs') ?? false;
			this._asTabs = this._asSlider;
		}

		if (this._asSliderWrapper) {
			if (this._asSliderWrapper.dataset.autoplay) {
				this._autoplay = parseInt(this._asSliderWrapper.dataset.autoplay);
			}

			if (this._asSliderWrapper.dataset.adaptiveHeight) {
				this._adaptiveHeight = this._asSliderWrapper.dataset.adaptiveHeight === 'true' ? true : false;
			}

		}
		if (this._asSlider && (this._gridItems.length > 1 || this._areaItems.length > 1) && this._asSliderWrapper) {

			//added swiper-slide class if needed
			[...this._gridItems, ...this._areaItems].forEach((item) => {
				if (!item.classList.contains('swiper-slide')) {
					item.classList.add('swiper-slide');
				}
			});

			if (this._asTabs) {
				let defaultTab = this.element.querySelector('.grid--default-tab');
				if (defaultTab) {
					initialIndex = [...this._gridItems].indexOf(defaultTab);
				}

				this._swiperOptions = {...this._swiperOptions, ...{ effect: 'fade', fadeEffect: { crossFade: true } }}
			}

			if (this._asSlider) {
				if (this._paginationElement && this._paginationElement.dataset.paginationType == 'dots') {
					this._swiperOptions = {...this._swiperOptions, ...{ pagination: { clickable: true, enabled: true } }}
				}
			}

			this._swiperOptions = {...this._swiperOptions, ...{
				modules: [ Pagination, EffectFade, Autoplay ],
				createElements: true,
				autoHeight: this._adaptiveHeight,
				freeMode: false,
				speed: 1000,
				loop: false,
				initialSlide: initialIndex,
				autoplay: (!this._autoplay || this._autoplay <= 0) 
					? false 
					: { delay: this._autoplay, disableOnInteraction: false },
			}};

			this._swiper = new Swiper(this._asSliderWrapper, this._swiperOptions);

			if (this._paginationElement) {
				this._pagination = new DKPagination(this._paginationElement, this._element, this._app);
				if (this._asTabs) {
					this._pagination.setAdditionalActiveClass('button--active');
				}
				this._pagination.switchTo(initialIndex);
			}
		}

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		if (this._pagination && this._swiper) {
			this._element.addEventListener(Events.pagination.clicked, this._onPaginationItemClicked.bind(this));
			this._swiper.on('slideChange', this._onSlideChange.bind(this));
		}
	}

	_goto(index) {
		if (this._swiper) {
			this._swiper.slideTo(index);
		}
	}

	_onPaginationItemClicked(evt) {
		let idx = evt.detail.idx;

		if (device == 'phone') {
			window.scrollTo({
				top: Utils.Helper.getOffset(this._element).top - 50,
				left: 0,
				behavior: 'smooth'
			});
		}

		this._goto(idx);
	}

	_onSlideChange(swiper) {
		if(this._pagination) {
			this._pagination.switchTo(swiper.activeIndex);
		}
	}
}

export default Section;
